@import url('https://fonts.googleapis.com/css?family=Roboto|Abel|Ropa+Sans');

#root {
  height: 100%;
  width: 100%;
}

html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  margin: 0px;
  font-family: 'Roboto';
}

#routerDiv {
  height: 100vh;
  width: 100%;
}

a {
  color: #1b7fe1;
  text-decoration: none;
}

.fw {
  width: 100%;
}

.flex {
  display: flex;
}

.xcenter {
  justify-content: center;
}

.ycenter {
  align-items: center;
}

.xspace-between {
  justify-content: space-between;
}

.gap {
  gap: 10px;
}

.MuiDataGrid-columnHeaderTitleContainer {
  justify-content: space-between !important;
}

.MuiDataGrid-columnHeaderWrapper {
  background-color: #368ae4 !important;
  border: 1px solid #1b7fe1;
  color: white !important;
}

.MuiDataGrid-iconButtonContainer {
  display: none !important;
}
