.formControl {
  margin: 8px 0 4px 0 !important;
  background-color: white;
}

.redAlerts {
  margin: 0;
  color: red;
  font-size: 12px;
  padding: 3px;
}

.redAlerts {
  margin: 0;
  color: red;
  font-size: 12px;
  padding: 3px;
}
