.wrapper {
  //   background-color: red !important;
  border: none !important;

  .scheduled {
    color: #59a5f7;
  }

  .opened {
    color: #21ae98;
  }

  .sent {
    color: orange;
  }

  .error {
    color: #f55c47;
  }
}
