.tabsWrapper {
  height: 60px !important;

  button {
    height: 60px !important;
    min-height: 60px !important;
    // padding-top: 0px !important;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tabs {
    display: flex !important;
    flex-direction: row-reverse !important;
  }
}
