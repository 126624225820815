@import 'styles/variables.scss';

.communications {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: 15px;

  h5 {
    margin: 0px;
  }

  .top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-basis: 100%;
    border-bottom: 0.5px $clr-primary-gray solid;
  }

  .half {
    flex-basis: 50%;
    padding: 15px 5px;
  }

  .topCommunications {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 80px;
    padding: 0px;

    .noFollowUpsLabel {
      font-size: 12px !important;
    }

    h3 {
      margin: 0;
    }

    &.first {
      border-right: 1px $clr-primary-gray solid;
    }
  }

  .complete {
    flex-basis: 100%;

    .switch {
      display: flex;
      justify-content: space-around;
      padding: 15px 0;
      align-items: center;

      .enableFollowLabel {
        display: inline;
      }
    }

    .table {
      display: flex;
      justify-content: center;
      height: 250px;
      overflow-y: scroll;

      td {
        padding: 5px 10px;
      }
    }
  }

  .submitWrapper {
    width: 100%;
    text-align: center;
  }
}

@media screen and (min-width: 800px) and (max-width: 1225px) {
  .communications {
    padding: 15px;
    .top {
      padding: 0;
    }

    .topCommunications {
      height: auto;

      &.first {
        border-right: none;
        border-bottom: 0.5px $clr-primary-gray solid;
      }
    }

    .half,
    .complete {
      flex-basis: 100%;
      padding: 15px 0;
    }
  }
}
