.autoComplete {
  margin-top: 8px;
  background-color: white;

  &.disabled {
    input {
      color: black;
      background-color: #fff;
      cursor: not-allowed !important;
    }
  }

  input {
    padding: 1.5px !important;
  }

  // label {
  //   top: -5px !important;
  //   transform: translate(14px, 0px) scale(0.75) !important;
  // }

  &.empty {
    label {
      top: -7px !important;
      //ransform: translate(14px, 0px) scale(0.75) !important;
    }
  }
}

.redAlerts {
  margin: 0;
  color: red;
  font-size: 12px;
  padding: 3px;
}
