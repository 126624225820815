.redAlerts {
  margin: 0;
  color: red;
  font-size: 12px;
  padding: 3px;
}

.input {
  margin-top: 8px !important;
  margin-bottom: 4px !important;

  &.disabled {
    color: black;
    background-color: #fff;
    cursor: not-allowed !important;
    label {
      color: #acacac !important;
      cursor: not-allowed;
      font-size: 16px;
    }
    input,
    textarea {
      padding: 12px;
      color: black;
      cursor: not-allowed;
      font-size: 16px;
    }
  }
}
