.extraButtonsMainContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  padding: 30px 0 20px;

  button {
    cursor: pointer;
    color: #1b7fe1;
    width: 120px;
    border: 1px solid #c1c1c1;
    padding: 10px;
    font-weight: bold;
    border-radius: 10px;
    background-color: white;

    &.isActive {
      color: white;
      border: 1px solid #1b7fe1;
      background-color: #1b7fe1;
    }

    &:hover {
      color: white;
      border: 1px solid #1b7fe1;
      background-color: #1b7fe1;
    }

    &:focus {
      outline: transparent;
    }
  }
}
