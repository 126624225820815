.productionTable {
  .status {
    span {
      color: white;
      padding: 3px 9px;
      border-radius: 10px;

      &.verified {
        background-color: #1eae98;
      }

      &.failed {
        background-color: #f55c47;
      }

      &.unchecked {
        background-color: #ffcc29;
      }
    }
  }

  .enabledFollowUps {
    color: white;
    padding: 3px 9px;
    border-radius: 10px;

    &.enabled {
      background-color: #1eae98;
    }

    &.disabled {
      background-color: #f55c47;
    }
  }

  .bubbleChat {
    padding: 0px 5px;
    border-radius: 5px;
    color: white;

    &.red {
      background-color: red;
    }

    &.purple {
      background-color: purple;
    }

    &.orange {
      background-color: orange;
    }

    &.gray{
      background-color: lightgray;
    }
  }

  .lastFollowUpDate {
    display: flex;
    flex-direction: column;

    span {
      line-height: 12px;
      padding-top: 2px;
    }
  }
}
