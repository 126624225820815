.container {
  background-color: #dee8f2;
  padding-bottom: 50px;

  .wrapper {
    border-radius: 5px;
    padding-bottom: 15px;
    background-color: #fff;
    box-shadow: 0 2px 5px #7f7f7f, 0 2px 2px #7f7f7f;
  }
}

@media (max-width: 576px) {
  .container {
    .wrapper {
      padding: 0 0 50px 0;
    }
  }
}
