.optionsContainer {
  .editBtn {
    float: right;
  }
  
  .iconsContainer {
    display: flex;
    justify-content: flex-end;

    .pdfIcon {
      display: flex;
      font-size: 50px;
      color: #1b7fe1;
      cursor: pointer;
    }
  }
}
.inputsContainer {
  padding: 15px;
  border-radius: 5px;
  background-color: #eff6ff;

  &.emptyInputsContainer {
    background-color: transparent;
  }
}

.title {
  color: #283160;
  margin: 0;
}
.subTitle {
  color: #1b7fe1;
  margin: 0px;
}
