.wrapper {
  background-color: #dee8f2;

  .formWrapper {
    background-color: white;
    margin: 15px 0 30px 0;
    padding: 15px;

    h1 {
      margin: 0px;
      color: #283160;
    }
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
}
