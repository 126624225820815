@import 'styles/variables.scss';

.wrapper {
  display: flex;
  gap: 15px;
  background-color: #dee8f2;
  color: $clr-primary-gray;
  margin-bottom: 15px;
  font-size: 14px;

  h1,
  h3 {
    font-size: 25px;
  }

  h1,
  h2,
  h5,
  h3,
  h4 {
    margin-bottom: 10px;
    color: $clr-primary-blue;
  }

  .content {
    background-color: $clr-primary-white;
    padding: 15px;
  }

  .patient,
  .communications {
    flex-basis: 25%;
    // max-width: 25%;
  }

  .communications {
    background-color: $clr-primary-white;
  }

  .treatment {
    flex-basis: 50%;
    // max-width: 50%;
  }
}

.insurances {
  background-color: white;

  .placeholder {
    text-align: center;
    font-size: 24px;
    color: $clr-primary-gray;
  }
}

@media (max-width: 890px) {
  .wrapper {
    flex-wrap: wrap;

    .patient,
    .communications,
    .treatment {
      flex-basis: 100%;
    }
  }
}
