.tabs {
  justify-content: center;
  height: 35px !important;
  min-height: 35px !important;

  .tab{
    height: 35px !important;
    min-height: 35px !important;
  }

  .linkWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    font-size: 14px;
  }
}
