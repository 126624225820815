@import 'styles/variables.scss';

.patientInfo {
  background-color: $clr-primary-white;
  position: relative;
  //   padding: 10px;
  .information {
    .personalInfo > * {
      margin-bottom: 2.8px;
    }
  }
}
