@import 'styles/variables.scss';

.wrapper {
  display: flex;
  justify-content: space-around;
  background-color: $clr-primary-light-gray;
  padding: 15px 0;
  background-color: $clr-primary-lighther-blue;

  .filterIcon {
    cursor: pointer;
    position: absolute;
    bottom: 15px;
    right: 45px;

    &.active {
      background-color: $clr-primary-blue;
      color: white;
      border-radius: 5px;
      font-weight: 900;
    }
  }

  .refreshIcon {
    cursor: pointer;
    position: absolute;
    bottom: 15px;
    right: 15px;
  }

  .box {
    flex-basis: 30%;
    // max-width: 30%;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $clr-primary-blue;
    flex-direction: column;
    background-color: $clr-primary-white;
    position: relative;
  }
}
