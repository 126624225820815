.wrapper {
  margin-bottom: 15px;

  .insuranceTitle {
    margin: 0 0 5px 0;
    color: #1b7fe1;
  }

  .insuranceSubtitle {
    margin: 0 0 5px 0;
    color: #283160;
  }
}
