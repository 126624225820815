@import 'styles/variables.scss';

.primaryButton {
  background-color: $clr-primary-blue !important;
  color: $clr-primary-white !important;
  border-radius: 0px !important;
  padding: 4px 8px !important;

  &.fullWidth {
    width: 100% !important;
  }

  &.disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
    pointer-events: unset !important;
  }
  &.secondaryButton {
    background-color: grey !important;

  }
}
