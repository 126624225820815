/**********************************************************************************/
/* White */
/**********************************************************************************/
$clr-primary-white: #ffffff;
$clr-primary-light-white: #fafafa;
$clr-primary-lighther-white: #fcfcfc;
$clr-primary-dark-white: #f0f0f0;
$clr-primary-darker-white: #f5f5f5;
$clr-secondary-white: #dddddd;
$clr-secondary-light-white: #d6d6d6;
$clr-secondary-lighther-white: #d8d8d8;
$clr-secondary-dark-white: #e5e5e5;

/**********************************************************************************/
/* Blue */
/**********************************************************************************/

$clr-primary-blue: #1b7fe1;
$clr-primary-light-blue: #1b7fe1;
$clr-primary-lighther-blue: #dee8f2;
$clr-primary-dark-blue: #f0f0f0;
$clr-primary-darker-blue: #f5f5f5;
$clr-secondary-blue: #dddddd;
$clr-secondary-light-blue: #d6d6d6;
$clr-secondary-lighther-blue: #d8d8d8;
$clr-secondary-dark-blue: #e5e5e5;

/**********************************************************************************/
/* Gray */
/**********************************************************************************/
$clr-primary-gray: #8b8b8b;
$clr-primary-light-gray: #c1c1c1;
$clr-primary-lighther-gray: #fcfcfc;
$clr-primary-dark-gray: #f0f0f0;
$clr-primary-darker-gray: #f5f5f5;
$clr-secondary-gray: #dddddd;
$clr-secondary-light-gray: #d6d6d6;
$clr-secondary-lighther-gray: #d8d8d8;
$clr-secondary-dark-gray: #e5e5e5;