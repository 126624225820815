@import 'styles/variables.scss';

.dialog {
  .dialogTitle {
    color: $clr-primary-white;
    background-color: $clr-primary-blue;
    text-align: center;
    text-transform: capitalize;

    h2 {
      display: flex;
      align-items: center;
      justify-content: center;

      .closeIcon {
        position: absolute;
        right: 15px;
        cursor: pointer;
        color: $clr-primary-white;
      }
    }
  }
  .dialogContent {
  }
}
