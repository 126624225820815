.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .placeholder {
    color: #707070;
    padding: 50px;
    font-size: 18px;
  }
}
