@import 'styles/variables.scss';

.wrapper {
  padding: 15px;
  background-color: #dee8f2;
  height: calc(100% - 100px);

  .insurances {
    background-color: white;
  }
}
