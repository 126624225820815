@import 'styles/variables.scss';

.divider {
  display: flex;
  margin-top: 8px;
  margin-bottom: 4px;

  .label {
    width: 100%;
    color: $clr-primary-blue;

    display: flex;
    align-items: center;
    text-align: center;

    &::after {
      content: '';
      flex: 1;
      border-bottom: 1.5px solid $clr-primary-blue;
      margin-left: 10px;
    }

    &:not(:empty)::before {
      margin-right: 0.25em;
    }
  }

  .line {
    flex-basis: 100%;
    border-top: 2px solid $clr-primary-blue;
  }
}
