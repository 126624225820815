.loader {
  position: absolute;
  top: 50%;
  right: 50%;
  z-index: 3;
}
.overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
}
